import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import { getFirstRoute, isUserLoggedIn, setHistoryState } from './utils'
import { CATEGORY_0_LIST, CATEGORY_1_LIST, COMPONENT_LIST, COURSE_LIST, HOME, LESSONS_LIST, MENTOR_LIST, SSO_AUTH, SSO_LOGIN } from './types'
import isProduction from '@/utils/is-production'
import routes from '~pages'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...setupLayouts(routes),

    {
      // redirect the user to the first page of the commercial menu
      path: '/commercial',
      redirect: () => {
        const route = getFirstRoute({ category: 'COMMERCIAL' })

        return { name: route.name }
      },
    },
    {
      // redirect the user to the first page of the learning management menu
      path: '/learning-management',
      redirect: () => {
        const route = getFirstRoute({ category: 'LEARNING MANAGEMENT' })

        return { name: route.name }
      },
    },
    {
      // redirect the user to the list page
      path: '/learning-management/courses/edit',
      redirect: { name: COURSE_LIST },
    },
    {
      // redirect the user to the list page
      path: '/learning-management/lessons/edit',
      redirect: { name: LESSONS_LIST },
    },
    {
      // redirect the user to the list page
      path: '/learning-management/component/edit',
      redirect: { name: COMPONENT_LIST },
    },
    {
      // redirect the user to the list page
      path: '/learning-management/category-1/edit',
      redirect: { name: CATEGORY_1_LIST },
    },
    {
      // redirect the user to the list page
      path: '/learning-management/category-0/edit',
      redirect: { name: CATEGORY_0_LIST },
    },
    {
      // redirect the user to the list page
      path: '/learning-management/mentor/edit',
      redirect: { name: MENTOR_LIST },
    },
    {
      // redirect old login to the new one, will deprecate old login later
      path: '/login',
      redirect: { name: SSO_LOGIN },
    },
  ],
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  // TODO: remove this if the new login method has been implemented
  if (to.name === SSO_LOGIN) {
    return next()
  }

  if (to.name === SSO_AUTH) {
    return next()
  }

  // the user cannot access the page before the user is logged in
  if (!isLoggedIn && to.name !== SSO_LOGIN) {
    // set the history state to redirect the user to the previous destination page
    setHistoryState(to)

    return next({ name: SSO_LOGIN })
  }

  // the user cannot access the login page if the user is already logged in
  if (isLoggedIn && to.name === SSO_LOGIN) {
    return next({ name: HOME })
  }

  // the user cannot access the page that is disabled in production
  if (isProduction && to.meta.disabledInProduction) {
    return next({ name: HOME })
  }

  return next()
})

export default router
