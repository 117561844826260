import type { VuetifyOptions } from 'vuetify'
import { themeConfig } from '@themeConfig'

const theme: VuetifyOptions['theme'] = {
  defaultTheme: localStorage.getItem(`${themeConfig.app.title}-theme`) || themeConfig.app.theme.value,
  themes: {
    light: {
      dark: false,
      colors: {
        'primary-main': localStorage.getItem(`${themeConfig.app.title}-lightThemePrimaryColor`) || '#1AA294',
        'primary-alternative-light': '#A3DAD4',
        'primary-alternative-dark': '#127168',
        'primary-contrast': '#FFFFFF',
        'primary-contained-hover-background': '#5FBEB4',
        'primary-outlined-hover-background': '#5FBEB41A',
        'primary-outlined-resting-background': '#5FBEB480',
        'secondary-main': '#2C1A32',
        'secondary-alternative-light': '#6B5F6F',
        'secondary-alternative-dark': '#413147',
        'secondary-contrast': '#FFFFFF',
        'secondary-contained-hover-background': '#ABA3AD',
        'secondary-outlined-hover-background': '#2C1A3214',
        'secondary-outlined-resting-background': '#2C1A3280',
        'info-main': '#2A88AA',
        'info-alternative-light': '#AACFDD',
        'info-alternative-dark': '#0E71A3',
        'info-contrast': '#FFFFFF',
        'info-contained-hover-background': '#6AACC3',
        'info-outlined-hover-background': '#6AACC314',
        'info-outlined-resting-background': '#6AACC380',
        'success-main': '#F5C577',
        'success-alternative-light': '#FBE8C9',
        'success-alternative-dark': '#AC8A53',
        'success-contrast': '#413147',
        'success-contained-hover-background': '#E09E00',
        'success-outlined-hover-background': '#E09E001A',
        'success-outlined-resting-background': '#E09E0080',
        'warning-main': '#F07A5A',
        'warning-alternative-light': '#F9CABD',
        'warning-alternative-dark': '#A8553F',
        'warning-contrast': '#FFFFFF',
        'warning-contained-hover-background': '#F1876A',
        'warning-outlined-hover-background': '#F1876A14',
        'warning-outlined-resting-background': '#F1876A80',
        'error-main': '#CB4340',
        'error-alternative-light': '#E86661',
        'error-alternative-dark': '#A33134',
        'error-contrast': '#FFFFFF',
        'error-contained-hover-background': '#CB4340',
        'error-outlined-hover-background': '#CB434014',
        'error-outlined-resting-background': '#CB434080',
        'text-primary': '#231528E6',
        'text-secondary': '#231528CC',
        'text-disable': '#23152861',
        'action-active': '#3A35418A',
        'action-hover': '#3A35410A',
        'action-selected': '#3A354114',
        'action-disabled': '#3A354142',
        'action-disabled-background': '#3A35411F',
        'action-focus': '#3A35411F',
        'other-divider': '#3A354126',
        'other-outline-border': '#3A35413B',
        'other-input-line': '#3A354138',
        'other-overlay': '#3A354180',
        'other-snackbar-background': '#212121',
        'other-body-background': '#F4F5FA',
        'other-paper': '#FFFFFF',
        'custom-background-primary': '#F5C577',
        'custom-background-secondary': '#2C1A32',
        'custom-background-info': '#2A88AA',
        'custom-background-success': '#1AA294',
        'custom-background-warning': '#F07A5A',
        'custom-background-error': '#CB4340',
        'custom-background-menu-active': '#127168',
        primary: localStorage.getItem(`${themeConfig.app.title}-lightThemePrimaryColor`) || '#1AA294',
        secondary: '#8A8D93',
        'on-secondary': '#fff',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        'on-primary': '#FFFFFF',
        'on-success': '#FFFFFF',
        'on-warning': '#FFFFFF',
        background: '#F4F5FA',
        'on-background': '#3A3541',
        'on-surface': '#3A3541',
        'grey-50': '#FAFAFA',
        'grey-100': '#F5F5F5',
        'grey-200': '#EEEEEE',
        'grey-300': '#E0E0E0',
        'grey-400': '#BDBDBD',
        'grey-500': '#9E9E9E',
        'grey-600': '#757575',
        'grey-700': '#616161',
        'grey-800': '#424242',
        'grey-900': '#212121',
      },

      variables: {
        'border-color': '#3A3541',
        'medium-emphasis-opacity': 0.68,

        // Shadows
        'shadow-key-umbra-opacity': 'rgba(var(--v-theme-on-surface), 0.08)',
        'shadow-key-penumbra-opacity': 'rgba(var(--v-theme-on-surface), 0.12)',
        'shadow-key-ambient-opacity': 'rgba(var(--v-theme-on-surface), 0.04)',
      },
    },
    dark: {
      dark: true,
      colors: {
        primary: localStorage.getItem(`${themeConfig.app.title}-darkThemePrimaryColor`) || '#1AA294',
        secondary: '#8A8D93',
        'on-secondary': '#fff',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        'on-primary': '#FFFFFF',
        'on-success': '#FFFFFF',
        'on-warning': '#FFFFFF',
        background: '#28243D',
        'on-background': '#E7E3FC',
        surface: '#312D4B',
        'on-surface': '#E7E3FC',
        'grey-50': '#2A2E42',
        'grey-100': '#2F3349',
        'grey-200': '#4A5072',
        'grey-300': '#5E6692',
        'grey-400': '#7983BB',
        'grey-500': '#8692D0',
        'grey-600': '#AAB3DE',
        'grey-700': '#B6BEE3',
        'grey-800': '#CFD3EC',
        'grey-900': '#E7E9F6',
      },
      variables: {
        'border-color': '#E7E3FC',
        'medium-emphasis-opacity': 0.68,

        // Shadows
        'shadow-key-umbra-opacity': 'rgba(20, 18, 33, 0.08)',
        'shadow-key-penumbra-opacity': 'rgba(20, 18, 33, 0.12)',
        'shadow-key-ambient-opacity': 'rgba(20, 18, 33, 0.04)',
      },
    },
  },
}

export default theme
