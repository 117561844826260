/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import api from '@/plugins/api'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import '@/styles/styles.scss'
import '@core/scss/index.scss'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

loadFonts()

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_DOMAIN, /^\//],
    }),
  ],
  tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) || 1.0,
  environment: import.meta.env.VITE_APP_ENVIRONMENT || 'local',
})

app.use(vuetify)
app.use(createPinia())
app.use(router)
app.use(api)
app.use(layoutsPlugin)

app.mount('#app')
