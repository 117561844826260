import { defineStore } from 'pinia'
import type { LoginUserRequestBody } from '@kunciehub/backoffice-api-client'
import type { IAuthParams } from '@/types/auth'
import { setupAPIV1 } from '@/plugins/api'

const isDevelopment = import.meta.env.DEV
const api = setupAPIV1()

export const useAuthStore = defineStore('AuthStore', {
  state: () => ({
    isLogin: false,
    accessToken: '',
    errorMessage: '',
  }),
  getters: {
    getIsUserLogin: state => state.isLogin,
    getAccessToken: state => state.accessToken,
    getErrorMessage: state => state.errorMessage,
  },
  actions: {
    // 👉 Login
    async authLogin(params: IAuthParams) {
      const authParam = {
        email: params.email,
        password: params.password,
      } as LoginUserRequestBody
      try {
        return await api.authUser
          .loginUser(authParam)
          .then(res => {
            const token = res.data.data.access_token
            this.isLogin = true
            this.accessToken = token
            localStorage.setItem('accessToken', token)

            return res
          })
          .catch(errLogin => {
            this.onError(errLogin, 'Axios Login Error')

            return errLogin
          })
      } catch (errorTryLogin) {
        this.onError(errorTryLogin, 'Try Login Error')

        return errorTryLogin
      }
    },

    // 👉 Logout
    async authLogout() {
      try {
        return await api.authUser
          .logoutUser()
          .then(() => {
            this.isLogin = false
            this.accessToken = ''
            localStorage.removeItem('accessToken')
            sessionStorage.removeItem('accessToken')
          })
          .catch(errLogout => {
            this.onError(errLogout, 'Axios Logout Error')

            return errLogout
          })
      } catch (errorTryLogout) {
        this.onError(errorTryLogout, 'Try Logout Error')

        return errorTryLogout
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError(error: any, message: string) {
      this.isLogin = false
      this.accessToken = ''
      if (error && Number(error.status) === 400) {
        this.errorMessage = ''
        if (isDevelopment) {
          console.error(`${message} => ${error?.message}`)
        }
      } else {
        if (error && error.message) {
          this.errorMessage = error.message.toString()
        } else if (error) {
          this.errorMessage = error.toString()
        } else {
          this.errorMessage = 'Something went wrong!'
        }
        if (isDevelopment) {
          console.error(`${message} => ${this.errorMessage}`)
        }
      }
    },

    resetError() {
      this.errorMessage = ''
    },
  },
})
