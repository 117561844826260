<script lang="ts">
import { getFirstRoute } from '@/router/utils'

export default defineComponent({
  name: 'Home',
  beforeRouteEnter(to, from, next) {
    // redirect the user to the first page of the menu
    const route = getFirstRoute()
    next({ name: route.name })
  },
})
</script>
