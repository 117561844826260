<script lang="ts">
import avatar1 from '@/assets/images/avatars/avatar-1.png'
import { SSO_LOGIN } from '@/router/types'
import { useAuthStore } from '@/store/auth'

export default {
  name: 'UserProfile',
  setup: () => ({
    router: useRouter(),
  }),
  data() {
    return {
      avatar1,
    }
  },
  methods: {
    ...mapActions(useAuthStore, ['authLogout', 'resetError']),
    onLogout() {
      // TODO: If API Logout is used, uncomment this code
      // this.authLogout()
      //   .then(() => {
      //     this.router.replace('/login')
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })

      if (localStorage.getItem('accessToken')) {
        localStorage.removeItem('accessToken')
      }

      if (sessionStorage.getItem('accessToken')) {
        sessionStorage.removeItem('accessToken')
      }

      this.router.replace({ name: SSO_LOGIN })
    },
  },
}
</script>

<template>
  <VBadge
    dot
    location="bottom right"
    offset-x="3"
    offset-y="3"
    color="success"
  >
    <VAvatar
      class="cursor-pointer"
      color="primary"
      variant="tonal"
    >
      <VImg
        :src="avatar1"
        alt="img-user-avatar"
        data-testid="img-user-avatar"
      />

      <!-- SECTION Menu -->
      <VMenu
        activator="parent"
        width="230"
        location="bottom end"
        offset="14px"
      >
        <VList>
          <!-- 👉 User Avatar & Name -->
          <VListItem data-testid="list-menu-user">
            <template #prepend>
              <VListItemAction start>
                <VBadge
                  dot
                  location="bottom right"
                  offset-x="3"
                  offset-y="3"
                  color="success"
                >
                  <VAvatar
                    color="primary"
                    variant="tonal"
                  >
                    <VImg
                      :src="avatar1"
                      alt="img-user-avatar-menu"
                      data-testid="img-user-avatar-menu"
                    />
                  </VAvatar>
                </VBadge>
              </VListItemAction>
            </template>

            <VListItemTitle class="font-weight-semibold"> Admin Kuncie </VListItemTitle>
            <VListItemSubtitle>Admin</VListItemSubtitle>
          </VListItem>

          <!-- Divider -->
          <VDivider class="my-2" />

          <!-- 👉 Logout -->
          <VListItem
            data-testid="btn-logout"
            @click.prevent="onLogout"
          >
            <template #prepend>
              <VIcon
                class="me-2"
                data-testid="icon-logout"
                icon="mdi-logout"
                size="22"
              />
            </template>

            <VListItemTitle>Logout</VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>
      <!-- !SECTION -->
    </VAvatar>
  </VBadge>
</template>
