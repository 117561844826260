<script lang="ts" setup>
import { useSkins } from '@core/composable/useSkins'
import { useThemeConfig } from '@core/composable/useThemeConfig'

// Components
import UserProfile from '@/layouts/components/UserProfile.vue'

// @layouts plugin
import { VerticalNavLayout } from '@layouts'

import type { VerticalNavItems } from '@/@layouts/types'

defineProps<{
  navItems: VerticalNavItems[]
}>()
const { appRouteTransition, isLessThanOverlayNavBreakpoint } = useThemeConfig()
const { width: windowWidth } = useWindowSize()

// `layoutAttrs` provides skin classes and vertical nav props for semi-dark styling
const { layoutAttrs, injectSkinClasses } = useSkins()

// ℹ️ This will inject classes in body tag for accurate styling
injectSkinClasses()
</script>

<template>
  <VerticalNavLayout
    :nav-items="navItems"
    v-bind="layoutAttrs"
  >
    <!-- 👉 navbar -->
    <template #navbar="{ toggleVerticalOverlayNavActive }">
      <div class="d-flex h-100 align-center">
        <VBtn
          v-if="isLessThanOverlayNavBreakpoint(windowWidth)"
          icon
          variant="text"
          color="default"
          class="ms-n3"
          size="small"
          data-testid="btn-toggle-sidebar"
          @click="toggleVerticalOverlayNavActive(true)"
        >
          <VIcon
            icon="mdi-menu"
            data-testid="icon-toggle-sidebar"
            size="24"
          />
        </VBtn>
        <VSpacer />

        <UserProfile />
      </div>
    </template>

    <!-- 👉 Pages -->
    <RouterView v-slot="{ Component }">
      <Transition
        :name="appRouteTransition"
        mode="out-in"
      >
        <Component :is="Component" />
      </Transition>
    </RouterView>
  </VerticalNavLayout>
</template>

<style lang="scss" scoped>
:global(.layout-nav-type-vertical.layout-vertical-nav-collapsed .layout-vertical-nav .nav-header) {
  padding-left: 12px;
}

:global(.layout-nav-type-vertical .layout-vertical-nav .nav-section-title:first-child) {
  margin-block-start: 1.5rem;
}
</style>
